import { Injectable } from '@angular/core';
import { ActiveEmailApi } from '../apis/active-email.api.js';

@Injectable({ providedIn: 'root' })
export class ActiveEmailGear {
  constructor(private activeEmailApi: ActiveEmailApi) {}

  send(email: string) {
    return this.activeEmailApi.send(email);
  }
}
