import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ActiveEmailApi {
  constructor(private apollo: Apollo, private httpClient: HttpClient) {}

  get() {}

  send(email: string) {
    if (email && email.length > 15) {
      const url = `/api/forgot-password/send-active-email?email=${email}&origin=${window.location.origin}`;
      return this.httpClient.get(`${url}`);
    } else {
      return of();
    }
  }
}
